
import { onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import {useStore} from "vuex";

export default {
  name: "Messaging",

  setup() {
    const store = useStore();
    const u = localStorage.getItem("user") as any;
    const user = JSON.parse(u);
    const dataList = [
      {
        name: "imessaging_groupage_type",
        route: "/messaging_groupage_type",
        icon: "las la-clone",
        count: "",
        permission: "location: index",
      },
      {
        name: "imessaging_iftdgn_cargo_type",
        route: "/messaging_cargo_type",
        icon: "las la-luggage-cart",
        count: "",
        permission: "location: index",
      },
      {
        name: "imessaging_operation_instruction",
        route: "/messaging_operation_instruction",
        icon: "las la-sticky-note",
        count: "",
        permission: "location: index",
      },
      {
        name: "imessaging_transport_method",
        route: "/messaging_transport_method",
        icon: "las la-parachute-box",
        count: "",
        permission: "location: index",
      },
    ];
    const dataObject = ref<[] | any>([]);
    const search = ref("");
    const permissions = ref<[] | any>([]);

    const onSearch = () => {
      dataObject.value = dataList.filter(
        (x) =>
          JSON.stringify(x)
            .toLowerCase()
            .indexOf(search.value.toLowerCase()) !== -1
      );
    };

    const canViewCatalog = (item) => {
      if (JSON.stringify(user) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === item.permission).length > 0
        );
      }
      return false;
    };

    onMounted(() => {
      store.commit("setLoadingStatus", true);
      ApiService.get(`/api/permissions/me`)
        .then(({ data }) => {
          permissions.value = data;
        })
        .catch((error) => {
          console.log(error);
          store.commit("setLoadingStatus", false);
        });
      setCurrentPageBreadcrumbs("imessaging", []);
      dataObject.value = dataList;
      ApiService.get("/api/catalogs/others").then(({ data }) => {
        dataObject.value.forEach((item, index) => {
          item.count = data[index];
          store.commit("setLoadingStatus", false);
        });
      });
    });

    return {
      dataObject,
      dataList,
      search,
      canViewCatalog,
      onSearch,
      permissions,
    };
  },
};
